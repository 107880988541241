import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {Link} from "gatsby";

const Holder = styled.p`
  margin: 0;
  a { 
    display: inline-block;
    + a {
      border-left: 1px solid;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
`;

function Pagination({next, prev}) {
  return (
    <Holder>
      {prev && <a href={`/project/${prev}`}>Previous project</a>}
      {next && <a href={`/project/${next}`}>Next project</a>}
    </Holder>
  )
}

Pagination.propTypes = {
  next: PropTypes.string,
  prev: PropTypes.string,
};

export default Pagination;