import React from "react"
import {graphql} from "gatsby"
import PrismicRichText from "../components/atoms/PrismicRichText";
import Seo from "../components/molecules/Seo";
import styled from "styled-components";
import Pagination from "../components/organisms/Pagination";
import Asset from "../components/atoms/Asset";
import AssetHolder from "../components/atoms/AssetHolder";
import ScrollHolder from "../components/molecules/ScrollHolder";

const Holder = styled.div`
  .scrolling-item-2 {
    @media ( ${props => props.theme.breakpoints.sm} ) {
      top: 50vh;
    }
  }
`;

const Intro = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 50px;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    width: 600px;
    position: relative;
    z-index: 10;
    margin-bottom: 0;
    top: 170px !important;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

function Project({data, pageContext}) {
    const {title, description, credits, page_assets} = data.prismicProject.data;
    return (
        <Holder>
            <Seo title={title.text}/>
            <ScrollHolder
                outro={<>
                    <PrismicRichText render={credits.richText}/>
                    <Pagination next={pageContext.next} prev={pageContext.previous}/>
                </>}
                projectPage>
                <Intro className="scrolling-item scrolling-item-1 intro" asset={{
                    scroll_speed: 4,
                    delay: 0
                }}>
                    <PrismicRichText render={description.richText}/>
                </Intro>
                {page_assets && page_assets.length > 0 && page_assets.map((asset, i) => <AssetHolder i={i + 1} key={i}
                                                                                                     asset={asset}>
                    <Asset asset={asset}/>

                </AssetHolder>)}
            </ScrollHolder>
        </Holder>
    )
}

export default Project

export const projectPageQuery = graphql`
    query($id: String!) {
        prismicProject(id: {eq: $id}) {
            data {
                title {
                    text
                }
                description {
                    richText
                }
                credits {
                    richText
                }
                page_assets {
                    image {
                        alt
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            srcSetMaxWidth: 1800
                            width: 1800
                            imgixParams: {q: 80}
                        )
                    }
                    video {
                        url
                    }
                    video_ratio_height
                    video_ratio_width
                    width
                    left_position
                    scroll_speed
                    delay
                }
            }
        }
    }
`;
